input[type=checkbox]:checked + label.todo_label{
    text-decoration: line-through;
}

.linethrough{
    text-decoration: line-through
}

.todo-title{
    font-style: normal;
    font-weight: bolder;
    letter-spacing: -0.01em;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #000000;
    margin:0;
}

.todo-input-container{
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 15px;
}

.todo-button-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
}

.todo-save-status-button{
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 105px;
    height: 37px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 13px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.text-list{
    text-align: left;
}

.todo-add-button-client{
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 105px;
    height: 37px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 13px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin-right: 2%;
}

.inputTodoClient{
    height:38px;
    width:100%;
    margin-right:2%;
}