.billing-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.popup-info-task {
    border: none;
    background-color: var(--main-graybk-color);
}

.billing-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #000000;
}

.billing-header-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:1px;
    width:100%;
}

.billing-option {
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000;
    cursor: pointer;
    border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
    width: 150px;
    height: 40px;
    margin-top: 60px;
}

.billing-option:hover {
    color: var(--main-green-color);
}

.selected-option {
    color: #FFF;
    background: var(--main-petroleum-color);
}

.billing-data-container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.billing-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* height: 271px; */
    flex-shrink: 0;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.40);

    max-width: 850px;
    margin: 0px;
}

.width-suscription{
    width: 730px;
}

.width-invoices{
    width: 740px;
}

.billing-subheader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 40px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 20px;

    color: #000;
    font-family: Borna;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    letter-spacing: -0.01em;
}

.overview {
    flex: none;
    order: 0;
    flex-grow: 0;
}

.info-summary-container{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
}

.info-summary {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex: none;
    order: 1;
    flex-grow: 0;
    background: #F5F5F5;
    width: 680px;
    margin-right:8px;
}

.info-summary-text-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 0px;
}

.info-summary-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 20px;
    gap: 10px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #000000;
}

.plan-information-container{
    display: flex;
    justify-content: space-between
}

.button-cancel-plan{
    display: flex;
    align-items: center;
    padding: 20px;
    margin-left: 20px;
    gap: 10px;
    width: 130px;
    height: 37px;
    background: #FF5C34;
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.info-summary-button-container{
    display: flex;
    width: 42%;
    justify-content: flex-end;
    align-items: flex-end;
}

.button-cancel-plan{
    display: flex;
    width: 100px;
    height: 26px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #CFCFCF;
}

.billing-table {
    align-self: stretch;
}

@media screen and (max-width: 920px){
    .width-suscription{
        width: 100%;
    }
    .info-summary {
        width: 100%;
    }
    .plan-information-container{
        flex-direction: column;
    }
}