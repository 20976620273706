.boxPassword{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    height: 35px;
    padding-bottom: 10px;
}

.box-password-name {
    width: 70px;
}

.button-get-password {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;

    height: 35px;

    cursor: pointer;

    /* Naranja Assisttu */

    background: var(--main-petroleum-color);
    /* Naranja/Active */

    border-radius: 10px;

    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    /* Blanco */

    color: #FFFFFF;
    transition: all 0.2s ease-in-out;
}



.secret-value-task {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid #A4A4A4;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background-color: #E0DFDF;
    border: 0px;
    text-align: center;
}