.background-people {
    position: absolute;
    height: 58px;
    bottom: 0px;
    width: 100%;
    background-image: url("../../../assets/images/bk-people-white.png");
    background-size: contain;
    background-position: bottom;

}

.background-people-top {
    position: absolute;
    height: 45px;
    top: 0px;
    width: 100%;
    background-image: url("../../../assets/images/bk-people-green.png");
    background-size: contain;
    background-position: bottom;

}

.full-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
}

.register-wrap {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PhoneInputInput {
    border: none;
}

.PhoneInputInput:focus-visible {
    outline: none;
}

.react-datepicker__input-container {
    display: flex;
}

.steps-form {
    height: 100%;
    max-width: 1366px;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.signup-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 200px 0px 200px;
    position: relative;
    height: 100%;
    justify-content: flex-start;
    margin-top: 90px;
}

.signup-stepper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    flex: none;
    order: 1;
    flex-grow: 0;
    height: 52px;
}

.stepper-option {
    font-family: 'nib';

    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: #000000;
    gap: 3rem;
}

.stepper-option.active {
    display: flex;
    align-items: center;
    background-color: var(--main-petroleum-color);
    color: #FFFFFF;
    height: 100%;
}

.signup-stepper>.subtitle {
    color: #ffffff;
    font-family: 'aeonik';

}

.title-signup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    margin: 0;
}

.form-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 40px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    height: 100%;
}

.section-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    gap: 40px;
    justify-content: space-between;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.section-wrap-interest {
    max-width: 680px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px 50px;
    max-height: 270px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 15px 77px 0px 0px;
    margin-bottom: 25px;
}

.section-wrap-interest::-webkit-scrollbar {
    width: 12px;
}

.section-wrap-interest::-webkit-scrollbar-thumb {
    background-color: #9D9D9D;
    border-radius: 6px;
}

.section-wrap-interest::-webkit-scrollbar-track {
    background-color: #f1f1f100;
}

.section-wrap>div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 40px;
}


.section-wrap>div>div {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.error-message {
    color: #FF5C34;
    font-size: 12px;
    order: 2;
}

.error-message.terms {
    top: 420px;
    left: auto;
    color: #FF5C34;
}

.form-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px;
    gap: 40px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    font-size: 22px;
}

.frame-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.buttons-form.interests {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 280px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}


.buttons-form {
    display: flex;
    flex-direction: row;

    align-items: center;
    padding: 0px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.buttons-form>.button-signup {
    width: 254px;
}

.buttons-form.contact {
    justify-content: flex-end;
}

.buttons-form.interests {
    justify-content: space-between;
}

.button-signup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-family: 'nib';

    width: 81px;
    height: 40px;
    border-radius: 10px;

    flex: none;
    order: 0;
    flex-grow: 0;
    align-self: stretch;
    cursor: pointer;
    border: 2px solid var(--main-green-color);
    background: #FFFFFF;
    border-radius: 10px;
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    line-height: 24px;
}

.button-signup:hover,
.button-signup:active {
    background: var(--main-petroleum-color);
    border: none;
    color: #FFFFFF;
}


.section-header {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.section-header.terms>.subtitle {
    padding-bottom: 60px;
}

.section-header.otp>.subtitle {
    padding-bottom: 60px;
}

.frame-signup>.section-header {
    align-items: center;
    width: 100%;
}

.frame-signup>.section-header>.subtitle {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 10px;
    font-family: 'nib';

}

.section-detail {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    width: 100%;
}

.section-detail.left {
    text-align: left;
    font-family: 'aeonik';
}

.section-detail.center {
    text-align: center;
}

.contact-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;

    background: #FFFFFF;

    border: 1px solid #A4A4A4;

    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    flex: auto;
    order: 0;
    flex-grow: 1;
    outline: none;
}

.contact-input.error {
    border: 1px solid #FF5C34;
}

.select-input {
    width: inherit;
    color: #757575;
}

.select-input-choosen {
    color: #000000;
}

.select-interest {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 135px;
    width: 135px;
    margin-bottom: 20px;
}

.likes-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 111px;
    height: 111px;
    background: #FFFFFF;
    border: 0.5px solid #A4A4A4;
    box-shadow: 0px 2px 6px rgba(155, 93, 59, 0.18);
    border-radius: 100%;
    flex: none;
    order: 0;
    flex-grow: 1;
    cursor: pointer;
}

.likes-button.selected {
    border: 2px solid var(--main-petroleum-color);
}

.likes-button-children {
    font-family: 'aeonik';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 1;
}

.likes-button-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    gap: 10px;
    color: var(--main-petroleum-color);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-terms-conditions>.text-area-terms-conditions {
    text-align: justify;
    width: 50rem;
    height: 8rem;
    font-family: 'aeonik', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    border-radius: 1rem;
    padding: 1rem;
}

.text-terms-conditions>.text-area-terms-conditions::-webkit-scrollbar {
    width: 12px;
}

.text-terms-conditions>.text-area-terms-conditions::-webkit-scrollbar-thumb {
    background-color: #9D9D9D;
    border-radius: 6px;
}

.text-terms-conditions>.text-area-terms-conditions::-webkit-scrollbar-track {
    background-color: #f1f1f100;
}

.checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    padding-bottom: 2rem;
    gap: 10px;

    width: 669px;


    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

}

.checkbox-wrapper>span {
    font-family: 'aeonik';
    font-style: normal;
    font-size: 22px;
    order: 1;
    flex-grow: 0;
}

.checkbox-wrapper>span>a {

    color: var(--main-petroleum-color);
}

.checkbox-wrapper input[type="checkbox"] {
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 5px;
    border: 3px solid var(--main-petroleum-color);
    background-color: #FFFFFF;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.checkbox-wrapper input[type="checkbox"]:checked {
    background-color: var(--main-green-color);
    border-color: var(--main-petroleum-color);
}

.active {
    border-bottom: none;
}

body {
    overflow: auto;
}

@media only screen and (min-height: 750px) {
    .signup-steps {
        margin-top: 0px;
        justify-content: space-around; 
    }
}
/* Small devices (phones) */
@media only screen and (max-width: 600px) {

    .section-header {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }

    .frame-signup {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }

    .section-wrap {
        gap: 20px; 
    }

    .section-wrap-interest {
        padding: 0px;
        max-height: 300px;
    }

    .signup-steps .signup-stepper {
        padding-top: 0px;
    }

    .signup-steps .signup-stepper-contact {
        padding-top: 150px;
    }

    .signup-steps .signup-stepper-interest {
        padding-top: 370px;
    }

    .buttons-form.interests {
        flex-direction: row;
        gap: 30px;
    }

    .signup-steps {
        padding: 20px;
        justify-content: space-around;
        margin-top: unset;
    }

    .section-wrap>div {
        flex-direction: column;
        gap: 20px;
    }

    .buttons-form {
        flex-direction: column;
        gap: 20px;
    }

    .button-signup.next,
    .button-signup.previous {
        width: 45%;
    }

    body {
        overflow: scroll;
        padding: 0px;
    }

    .button-signup.next {
        order: 1;
    }

    .button-signup.previous {
        order: 0;
    }

    .three-div {
        display: none;
    }

    .terms-link {
        display: block
    }

    .checkbox-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        gap: 0px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-self: stretch;

    }
    .checkbox-wrapper input[type="checkbox"] {
        width: 30px;
    }

    .background-people-top {
        height: 52px;
        background-size: cover;
    }

    .text-terms-conditions>.text-area-terms-conditions {
        width: 20rem;
        height: 10rem;
    }
}

/* Medium devices (tablets) */
@media screen and (min-width: 600px) and (max-width: 900px) {

    .signup-steps {
        /* width: 80%; */
    }

    .buttons-form.interests {
        gap: 50px;
    }

    .register-wrap {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text-terms-conditions>.text-area-terms-conditions {
        width: 30rem;
        height: 10rem;
    }

}

@media screen and (max-height: 626px) {

    .full-frame {
        height: 100%;
    }
}

.left-arrow-register,
.right-arrow-register {
    width: 39px;
    height: 29px;
}