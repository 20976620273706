.grid{
    display: flex;
    width: 800px;
    justify-content: center;
    margin: auto;
}

@media screen and (max-width: 1000px){
    .grid{
        width: 100%;
    }   
}