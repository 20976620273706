.Message_Button {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 15%;
    height: 37px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    order: 1;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.Message_Label {
    display: flex;
    align-items: flex-start;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    order: 1;
    flex-grow: 0;
    padding-top: 10px;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    padding-left: 5%;
    padding-right: 5%;

  }

.Return_Button {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 30px;
    height: 37px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    order: 1;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.formChat{
    display:flex;
    margin-top: 10px;
}

.inputChat{
    height:40px;
    width:95%;
}

.Container{
    padding:3%;
}

.message-list{
    overflow-y: hidden;
}

.closed_Message{
    display: flex;
    align-items: flex-start;
    padding: 24px;
    width: 75%;
    height: 5px;
    border-radius: 7px;
    order: 1;
    flex-grow: 0;
    border: 1px solid;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: red;
}


.Container {
    position: relative;
    height: 420px;
    /* overflow: hidden; */
  }
  
.messageList {
    height: calc(100% - 50px);
    overflow-y: auto;
}

.inputChat { 
    position: absolute;
    /* bottom: 0;
    width: 100%;
    height: 50px; */
}

