.cuadroInfo {
    display: flex;
    flex-direction: column;
    border-radius: 10px ;
    width: 446px;
    height: 316px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
}

.name-container{
    display: flex;
    width:100%;
    margin-bottom: 10px;
}

.client-name{
    text-align: center;
    color: #000;
    font-family: 'Borna', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.imgUser{
    border-radius: 70px;
    height:130px;
}

@media screen and (max-width: 920px) {
    .cuadroInfo {
        flex-direction: column;
        gap: 0px;
        width: 100%;
    }
}