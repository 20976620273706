.data-tasks-container{
    padding: 0 3%;
}

#modal-task-assistant-select-label {
    top: -10px
}

#modal-task-assistant-select-label.Mui-focused, #modal-task-assistant-select-label.MuiFormLabel-filled.MuiInputLabel-shrink {
    top: 0px
}

.modal-task-assistant-title-task {
    color: var(--main-petroleum-color);
    margin-bottom: 5px;
}


.modal-task-assistant-title {
    margin-bottom: 10px;
}