.resend-code {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.section-wrap.otp-wrap{
    gap: 25px;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media only screen and (max-width: 1200px){
    .section-wrap.otp-wrap{
        gap: 25px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.container-resend-code {
    width: 100%;
    display: flex;
    justify-content: center;
}

.button-signup.resend-code-button {
    width: 254px;
    border: 1px solid #000;
    height: 50px;
}

.button-signup.resend-code-button:hover {
    border: 1px solid #000;
    background-color: #ebe8e8;
    color: #000;
}

.icon-resend-code-button {
    display: flex;
    align-items: center;
    transform: rotate(-60deg);
}

.input-otp {
    display: flex;
    justify-content: center;
}

.otp-container {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.otp-container>.otp-input, .otp-container>.otp-input:focus-visible {
    width: 40px;
    border: none;
    border-bottom: 1px solid;
    height: 30px;
    text-align: center;
    font-size: 22px;
}

.finish-button.inactive {
    background-color: gray;
    color: rgb(51, 51, 51);
    border: none;
}

.finish-button.inactive:hover {
    background-color: gray;
    color: rgb(51, 51, 51);
    border: none;
}

/* Small devices (phones) */
@media only screen and (max-width: 600px) {

    .otp-container {
        gap: 7px;
    }

    .otp-container>.otp-input, .otp-container>.otp-input:focus-visible {
        width: 30px;
    }


}