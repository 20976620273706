.tasks-header {

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 10px;
    width: 800px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: auto;
}

.counter-container-home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px ;
    width: 100px;
    height: 100px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    background-size: 120px;
    width: 100px;
    height: 100px;
    background-color: var(--main-petroleum-color);
    border-radius: 10px;
}

.new-task-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px ;
    width: 100px;
    height: 100px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    background-image: url(../../../assets/images/home/bk-new-task.png);
    background-size: 120px;
    width: 100px;
    height: 100px;
}

.new-task-content{
    font-size: 15px;
    color: #fff;
    font-family: 'aeonik', sans-serif;
}

.new-task-icon{
    font-size: 20px;
    color: #fff;
}


.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    font-family: 'aeonik'!important;
}

.textarea-description-task-title, .textarea-add-information-task-title{
    font-family: 'aeonik'!important;
}

.css-ahj2mt-MuiTypography-root{
    font-family: 'aeonik'!important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    font-family: 'aeonik'!important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    font-family: 'aeonik'!important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    font-family: 'aeonik'!important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    font-family: 'aeonik'!important;

}
.title-form{
    font-family: 'nib';
}
.head-counter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.tasks-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.tasks-counters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    height: 135px;

    border: 1px solid #A4A4A4;
    background: rgb(12,194,160);
background: linear-gradient(90deg, rgba(12,194,160,1) 0%, rgba(0,73,84,1) 50%, rgba(46,112,167,1) 100%);
    border-radius: 10px;

    flex: none;
    order: 0;
}

.counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 10px;

    width: 150px;

    flex: none;
    justify-content: space-between;
    cursor:pointer;
}


.counter-separator-container.one {
    order: 0;
}

.counter-in-progress {
    order: 1;
}

.counter-separator-container.two {
    order: 1;
}

.counter-done {
    order: 2;
}


.counter-separator-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.counter-separator {
    border: 1px solid #A4A4A4;
    transform: rotate(180deg);

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.Counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;

    text-align: center;
    letter-spacing: -0.01em;
    margin: 0;
}

.text-task {
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
}


.tasks-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.task-view-upper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px;
    gap: 10px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.text-helper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
    width: 800px;
}

.text-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
}

.helper {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.helper-counter {
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #2f2f2f;
    margin-bottom: 100px;
}


.task-option-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 20px;
    gap: 52px;
    width: 800px;
    margin: auto;


    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
}

.right-task-option-buttons{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 20px;
    gap: 52px;



    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
}

.button-task-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 8px;

    width: 150px;
    height: 30px;

    background: #FFFFFF;
    border: 1px solid #E4E8EB;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #000000;


    /* Inside auto layout */
    border-color: var(--main-green-color);
    flex: none;
    flex-grow: 0;
    cursor: pointer;
    position: relative;
    font-weight: bold;
}

.button-task-view:hover {
    background-color: var(--main-petroleum-color);
    color: #fff;
}


@keyframes ripple {
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(2);
        width: 50%;
        height: 50%;
    }
}

.new-task {
    order: 0;
}

.edit-task {
    order: 1;
}

.survey-task{
    order: 2;
}

.tasks-table {
    padding: 20px;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}


/* Modal Survey */

.ReactModal__Content {
    position: absolute;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    background-color: white;
    padding: 20px;
    height: fit-content;
    -webkit-box-shadow: 0px 4px 36px -4px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 4px 36px -4px rgba(0,0,0,0.55);
    box-shadow: 0px 4px 36px -4px rgba(0,0,0,0.55);
  }

  .create_Chat_Button, .create_Task_Button{
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 10px;

    height: 37px;
    
    margin: auto;
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
  }
  .create_Task_Button {
    background: var(--main-petroleum-color);
  }

  @media screen and (max-width: 920px) {

    .counter-container {
        gap: 0px;
        width: auto;    
    }

    .task-option-buttons{
        flex-direction: column;
        gap: 0px;
        width: 100%;
    }
    .tasks-info{
        padding: 0px;
    }
    .tasks-header {

        width: 100%;

    }

    .right-task-option-buttons{
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }

    .css-pzyknn {
    width: 50%;
    }

    .task-option-buttons{
        align-items: center;
    }

    .text-helper{
        width: 100%;
    }
    .tasks-header{
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: center;
        align-items: center;
    }

    .ContentHeader{
        flex-grow: 0;
    }

    .ReactModal__Content {
        width: 80%;
    }
}