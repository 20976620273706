.pm-box{
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
}

a{
        text-decoration: none;
}

.pm-box-text{
    font-size: 26px;

    font-family: 'aeonik';
}

.pm-box-icon{
    font-size: 30px;
    color: var(--main-petroleum-color);
    font-family: 'aeonik';
}