.rce-container-clist {
    display: block;
    overflow: auto;
  }

.ModalChat{
  width: 22rem;
  height: 500px;
  /* overflow: hidden; */
}
.open_window{
  margin-right: 0px;
  margin-left: 90%;
  width: 25px;
    height: 25px;
    z-index: 1;
}

.support-spreader{
  font-family: 'nib', sans-serif;
  color: var(--main-petroleum-color);
  margin-left: 5%;
  font-size: large;
  border-bottom: 2px solid;
  border-color: black;
  padding-bottom: 10px;
}

.rce-citem-body--top-title{
  font-weight: bold;
}
.rce-citem-body--top-time{
  font-family: 'borna', sans-serif;
}

@media screen and (max-width: 920px){
  .open_window{
    display: none;
  }

  .ModalChat{
    width: 100%;
    /* overflow: hidden; */
  }
  
}