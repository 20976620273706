.incomings-header {

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 10px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: auto;
}

.incomings-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.text-incoming {
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
}


.incomings-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.incoming-option-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 20px;
    gap: 52px;
    width: 100%;


    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
}

.right-incoming-option-buttons{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 20px;
    gap: 52px;



    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
}

.button-incoming-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 8px;

    width: 150px;
    height: 30px;

    background: #FFFFFF;
    border: 1px solid #E4E8EB;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #000000;


    /* Inside auto layout */
    border-color: var(--main-green-color);
    flex: none;
    flex-grow: 0;
    cursor: pointer;
    position: relative;
    font-weight: bold;
}

.button-incoming-view:hover {
    background-color: var(--main-petroleum-color);
    color: #fff;
}


@keyframes ripple {
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(2);
        width: 50%;
        height: 50%;
    }
}

.new-incoming {
    order: 0;
}

.edit-incoming {
    order: 1;
}

.incomings-table {
    padding: 20px;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

/* MODAL */
.label-incomingType {
    -webkit-transform: translate(14px, 10px) scale(1);
    -moz-transform: translate(14px, 10px) scale(1);
    -ms-transform: translate(14px, 10px) scale(1);
    transform: translate(14px, 10px) scale(1);
}

.type-field-incoming{
    display: grid; 
    grid-template-columns: 1fr 1fr;
    gap: 1rem; 
    padding-top: 20px;
    margin-left: -10px;
}

.incoming-photo-container{
    /* display: flex;
    width: 100%;
    justify-content: center;
    align-items: center; */
    margin-top:20px;
    position: relative;
    display: inline-block;
}

.incoming-photo-container input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    right: 0;
    opacity: 0;
}

.incoming-photo-container:hover{
    cursor:pointer;
}

.img-input-incoming:hover, #file-upload-button:hover {
    cursor: pointer;
}

.button-charge-img-incoming{
    background-color: var(--main-petroleum-color);
    width: 100%;
    min-height: 35px;
    font-size: 20px;
    color: #FFF;
    border-radius: 7px;
}


/* Modal Survey */

.ReactModal__Content {
    position: absolute;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    background-color: white;
    padding: 20px;
    height: fit-content;
    -webkit-box-shadow: 0px 4px 36px -4px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 4px 36px -4px rgba(0,0,0,0.55);
    box-shadow: 0px 4px 36px -4px rgba(0,0,0,0.55);
}

  .create_Chat_Button, .create_Task_Button, .incoming_Details_Button{
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 10px;

    height: 37px;
    
    margin: auto;
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
  }
  .create_Task_Button {
    background: var(--main-petroleum-color);
  }

  @media screen and (max-width: 600px) {

    .incoming-option-buttons{
        flex-direction: column;
        gap: 0px;
        width: 100%;
    }
    .incomings-info{
        padding: 0px;
    }
    .incomings-header {

        width: 100%;

    }

    .right-incoming-option-buttons{
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }

    .css-pzyknn {
    width: 50%;
    }

    .incoming-option-buttons{
        align-items: center;
    }
    .incomings-header{
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: center;
        align-items: center;
    }

    .ContentHeader{
        flex-grow: 0;
    }

    .ReactModal__Content {
        width: 80%;
    }
    .type-field-incoming{
        display: flex; 
        flex-direction: column;
        gap: 1rem; 
        padding-top: 20px;
        width: 100%;
    }
}

.gridIncomings{
    display: flex;
    width: 100%;
    justify-content: center;
}

@media screen and (max-width: 1000px){
    .gridIncomings{
        width: 100%;
    }   
}