.navbar {
    position: relative;
    top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 30px;
    gap: 221px;

    background: #ffffff;
    border-bottom: 1px solid rgb(189, 180, 166);

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    height: 10.8vh;
}

@media screen and (max-width: 920px) {
    /* .navbar {
        display: none;
    } */
}

.left-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 80px;

    width: 50%;
    height: 80px;

    flex: none;
    order: 0;
    flex-grow: 1;
}

.right-navbar {
    /* right-navbar */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 50px;

    width: 323px;
    height: 57px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.NavbarButton {
    /* button-messages */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;

    width: 218px;
    height: 57px;

    /* Naranja Assisttu */

    background: #FF5C34;
    border-radius: 10px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    border: none;
    cursor: pointer;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;

    /* Blanco */

    color: #FFFFFF;

}

.NavbarButton:hover {
    background: #ffffff;
    border-radius: 10px;
    border: 3px solid #515868;
    color: #FFFFFF;
    transition: 0.2s;
}

.navbar-separator {
    border: 1px solid #A4A4A4;
    transform: rotate(180deg);

    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.navbar-separator-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;

    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.NavbarAlertIcon {
    /* notif-bell */


    width: 40px;
    height:40px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}

.container-notifications{
    margin-left: -15px;
    margin-right: -15px;
    width: 70px;
    margin-top: 15px;
    height: 65px;
    flex-shrink: 0;
    border-radius: 30px 30px 0px 0px;
    background: var(--main-petroleum-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper{
    border-radius: 20px;
    margin-top:12px;
}

@media screen and (max-width: 920px){
    .right-navbar {
        display: none;
    }
    
}




.navbar-logo-container, .navbar-logo-container:hover {
    cursor: pointer;
}