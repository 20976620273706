.modal-task-header-right {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.modal-task-label {
    font-size: 15px;
    line-height: 15px;
    color: #000;
    font-weight: bold;
}

.modal-task-header-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    padding-top: 10px;
}

.modal-task-header-title>h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
    font-family: 'nib', sans-serif;
}

.modal-task-title, .modal-task-secrets {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    padding-left: 0px;
}

.modal-task-title-text, .modal-task-secrets-title {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
}

.modal-task-secrets-items {
    margin-top: 10px;
    padding: 15px;
    background-color: #FFF;
    word-wrap: break-word;
}

.modal-task-hours-date-limit {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.Mui-disabled {
    color: var(--main-petroleum-color)!important;
}

.css-eglki6-MuiLinearProgress-root{
    background-color: var(--main-petroleum-color)!important;
}

.date-limit-popup-info-task {
    color: var(--main-petroleum-color);
}

.popup-info-task {
    border: none;
    background-color: var(--main-graybk-color);
}

.modal-task-progress-title {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
}

.modal-task-progress-bar {
    order: 3;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal-task-progress-hours {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 0px;
}

.send-time-executed-Button {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    gap: 10px;
    height: 31px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.button-change-assistant {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    gap: 10px;
    height: 31px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.create_Chat_Button{
    border: 2px solid var(--main-petroleum-color);
    color: #000;
    font-weight: bold;
    order: 6;
}

.create_Chat_Button:hover {
    background: var(--main-petroleum-color);
    color: #FFF;
}