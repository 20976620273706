.switch-case-to-edit-recurring {
    height: 4rem;
    display: flex;
}

.inputs-edit-recurrences {
    display: grid; 
    grid-template-columns: 1fr 1fr;
    gap: 1rem; 
}

.buttons-edit-recurrences {
    display: flex;
    justify-content: center;
}