.cuadroInfoAssistants {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px ;
    width: 446px;
    height: 316px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
}

.name-container{
    display: flex;
    width:100%;
    margin-bottom: 10px;
}

.preferred-assistants-assistant-name{
    text-align: center;
    color: #000;
    font-family: 'aeonik', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 18%;
}

.imgUser{
    border-radius: 70px;
    height:130px;
}

.rating-box{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap:20px;
}

.rating-star{
    height: 25px;
    width: 25px;
}

/* .button-delete-container{
    display: flex;
    width: 90%;
    justify-content: right;
    margin: -15px 0;
} */

.button-delete-container{
    display: flex;
    justify-content: right;
    position: relative;
    top: 40%;
    left:10%;
}

.assistant-info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 270px;
    position: relative;
    left: 6%;
}

.button-delete-preferred-icon{
    height:23px;
    color: #000;
}

@media screen and (max-width: 920px) {
    .cuadroInfo {
        flex-direction: column;
        gap: 0px;
        width: 100%;
    }
}