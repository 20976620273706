.cuadroInfoTask {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px ;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    
}

.buttons-left{
    display: flex;
    justify-content: end;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}
.cuadroInfoTaskDetail {
    display: flex;
    flex-direction: row;
    border-radius: 10px ;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    
}

.task-icon{
    font-size: 25px;
}

.task-detail-date{
    width:80%;
    text-align: left;
    color: #000;
    font-family: 'aeonik', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 10%;
}

.progress-span{
    font-family: 'Borna', sans-serif;
    font-size: 15px;
    font-style: normal;

}

.cuadroSubInfoTask {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px ;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    flex-direction: row;
    
}

.name-container{
    display: flex;
    width:100%;
    margin-bottom: 10px;
}

.progress-container{
    display: flex;
    width:100%;
    flex-direction: column;
    margin-bottom: -10px;
    align-content: flex-end;
    padding-top: 10px;
    padding-left: 10px;
}

.sub-info-task-rigth{
    display: flex;
    width:100%;
    flex-direction: column;
}

.assistant-name{
    width:80%;
    text-align: center;
    color: #fff;
    font-family: 'Borna', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 10%;
}

.task-box{
    width:80%;
    text-align: center;
    color: #000;
    font-family: 'Borna', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 10%;
    font-family: 'Nib', sans-serif;
    
}

.imgUser{
    border-radius: 70px;
    height:130px;
}

.rating-box{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap:20px;
}

.rating-star{
    height: 25px;
    width: 25px;
}

.button-delete-container-1a{
    width: 8%;
}

.button-delete-preferred{
    display: flex;
    align-items: center;
    height: 37px;
    background: transparent;
    border: none;
    cursor: pointer;
    justify-content: center;
    color: #000;
}

@media screen and (max-width: 920px) {
    .cuadroInfo {
        flex-direction: column;
        gap: 0px;
        width: 100%;
    }
}