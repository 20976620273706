.rce-container-clist {
    display: block;
    overflow: auto;
  }

.ChatWindow{
  height: 75vh;
  width: 65vw; 
  /* overflow: hidden; */
}

.rce-citem{
  background: none;
}
.ChatDisplay{
  display: flex;
  flex-direction: row;
  /* overflow: hidden; */
}

.contactDisplay{
  display: flex;
  flex-direction: column;
  width: 25vw;
  /* overflow: hidden; */
}