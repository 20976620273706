.full-card-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    gap: 25px;
    max-width: 45%;

    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
    border-radius: 20px;

    flex: 1;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    border-color: var(--main-petroleum-color);
    border-width: 4px;
    border-style: solid;
}

.button-subscribe-container{
    margin-left: 0px;
}

.package-row{
    gap: 10px;
    display: flex;
    flex-direction: row;
}

.price-subdescription-package{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.full-price-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap:25px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    border: 20px;
}

.plan-header-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

}

.card-contract-title {
    display: flex;
    flex-direction: row;
    margin: 0px;
    color: var(--main-green-color);
    font-weight: bold;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-style: normal;
    font-size: 32px;
    letter-spacing: -0.01em;
    font-family: 'nib';
}

.price-frame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.price-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    flex: none;
    order: 0;
    align-self: stretch;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.01em;

    color: #FFF;
}

.price-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    flex: none;
    order: 1;
    flex-grow: 0;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: -0.01em;

    /* Gris oscuro */

    color: #696969;
}

.features-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 15px;

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.feature-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    gap: 10px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.features-list-container{
    display: flex;
    flex-direction: row;
}

.feature-text {
    font-family: 'nib';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;

    /* Negro completo */

    color: var(--main-petroleum-color);
}
.feature-text-bold {
    font-family: 'nib';
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
    margin-bottom: -25px;
    padding-left: 20px;
    /* Negro completo */

    color: var(--main-petroleum-color);
}

.button-subscribe {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    cursor: pointer;
    font-weight: bold;
    background: var(--main-green-color);
    border-radius: 30px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: var(--main-petroleum-color);
    transition: all 0.2s ease-in-out;
}
.select-plan-hours{
    order: 1;
}

.text-select-plan-hours{
    font-family: 'nib', 'aeonik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: var(--main-petroleum-color);
}

.button-subscribe-hour {
    order: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    cursor: pointer;
    margin-right: 10px;

    font-weight: bold;
    background: var(--main-green-color);
    border-radius: 30px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: var(--main-petroleum-color);
    transition: all 0.2s ease-in-out;
}


.button-subscribe-package {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    font-weight: bold;
    background: var(--main-green-color);
    border-radius: 30px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: var(--main-petroleum-color);
    transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 1200px) {
    .features-list-container{
        flex-direction: column;
    }
    .package-row{
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .full-card-pricing{
        max-width: 100%;
    }
}

