.header-plans {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.header-plans-text {
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #000000;
}

.plans-full-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 50px;
    gap: 50px;
    margin-top:20px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.plans-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 50px;

    flex: none;
    flex-wrap: wrap;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
}

.plans-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    width: 95%;
}

.promo-title {
    color: #004752;
    text-align: center;
    font-family: 'Borna', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.promo-label {
    color: #000;
    text-align: center;
    font-family: 'Aeonik', sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.promo-input {
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
}

.promo-button {
    padding: 8px 15px;
    border-radius: 8px;
    font-family: 'Aeonik';
}

.other-fields-formNeed{
    display: grid; 
    grid-template-columns: 1fr 1fr;
    gap: 1rem; 
    padding-top: 20px;
}

.other-fields-formCancel{
    grid-template-columns: 1fr 1fr;
    gap: 1rem; 
    padding-top: 20px;
}

.comments-field-formNeed{
    margin-top: 1rem;
    margin-left: 13px;
}

/* .button-need-help{
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 10px;

    height: 37px;
    
    margin: auto;
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    background: var(--main-petroleum-color);
} */

.button-need-help {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;

    width: 152px;
    height: 30px;

    background: #FFFFFF;
    border: 1px solid #E4E8EB;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #000000;


    /* Inside auto layout */
    border-color: var(--main-green-color);
    flex: none;
    flex-grow: 0;
    cursor: pointer;
    position: relative;
    font-weight: bold;
}

.button-need-help:hover {
    background-color: var(--main-petroleum-color);
    color: #fff;
}

.phone-input-need{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px;
    gap: 10px;
    border: 1px solid #A4A4A4;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    flex: auto;
    order: 0;
    flex-grow: 1;
}

.phone-input-need>input{
    background-color: transparent;
}

@media screen and (max-width: 600px) {
    .plans-cards{
        flex-direction: column;
    }
}

