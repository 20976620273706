.todo-general-container{
    padding: 0 4%;
}

.todo-title{
    font-style: normal;
    font-size: 2em;
    letter-spacing: -0.01em;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    color: var(--main-petroleum-color);
    margin:0 15px;
}

.todo-subtitle{
    font-style: normal;
    font-size: 1em;
    letter-spacing: -0.01em;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    color: var(--main-petroleum-color);
    margin:0 15px;
}

.list-todo{
    height: 320px;
    overflow-y: scroll;
    padding-right: 10px;
}

.list-todo::-webkit-scrollbar {
    width: 8px;
}

.list-todo::-webkit-scrollbar-thumb {
    background-color: #9D9D9D;
    border-radius: 6px;
}

.list-todo::-webkit-scrollbar-track {
    background-color: #f1f1f100;
}

.todo-item{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    padding: 10px;
}

.icon{
    justify-content: center;
}

.icon-color2{
    color: var(--main-petroleum-color);
}

.icon-color{
    color:#000;
}

.todo-add-button-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px;
    left: 0;
    bottom: 0;
    color: white;
    text-align: center;
}
    

.todo-add-button{
    display: flex;
    width: 20%;
    height: 45px;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border-color: transparent;
    font-weight: bold;
    color: #FFF;
    background: var(--main-petroleum-color);
    cursor: pointer;
}

.inputTodo{
    height:45px;
    width: 78%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.25);
}

.Return_Button {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 30px;
    height: 37px;
    border-radius: 7px;
    order: 1;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin-bottom: 10px;
}