.ListSidebar{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 10px;

    flex: none;
    flex-grow: 0;
    list-style: none;
    align-self: stretch;

    margin: 0px;

}

.ListSidebar>li {
    display: flex;
}

.MainListSidebar {
    z-index: 1;
    order: 0;


}

.SupportListSidebar {
    z-index: 0;
    order: 2;

    width: 100%;
}

.ListSidebar.collapsed {
    justify-content: center;
}

.MobileListSidebar {
    display: none;
}

@media screen and (max-width: 920px){
    .MobileListSidebar {
        z-index: 0;
        order: 1;
        display: flex;
    
        width: 100%;
    }
}
