.vault {
    width: 100%;
}

.bk-vault {
    position: fixed;
    height: 50px;
    bottom: 0px;
    width: 100%;
    background-image: url(../../../../assets/images/bk-people-green.png);
}

.inputs-form-vault {
    display: flex;
    gap: 40px;
}
.input-vault-name, .input-vault-pass{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.submit-form-vault {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.error-message-vault {
    /* position: absolute; */
    bottom: -20px;
    left: 0;
    color: var(--main-petroleum-color);
    font-size: 12px;
}

.data-vault-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    margin-top: 5px;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid #A4A4A4;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    flex: auto;
    flex-grow: 1;
    order: 0;
    flex-grow: 1;
    outline: none;
}

.button-submit.vault-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;
    border-radius: 10px;
    flex: none;
    flex-grow: 0;
    order: 0;
    flex-grow: 0;
    align-self: stretch;
    cursor: pointer;
    background: var(--main-petroleum-color);
    color: #FFFFFF;
}

.tabla-complete-vault {
    display: flex;
    width: 100%;
    justify-content: center;
}

.vault-table-info>thead>tr, .vault-table-actions>thead>tr {
    height: 25px;
}

.vault-table-section {
    width: 550px;
    padding: 30px 0px;
    text-align: center;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.40);
}

.vault-table {
    justify-content: center;
    display: flex;
}

.vault-table-info>tbody>tr {
    background-color: #F5F5F5;
}

.vault-table-info>tbody>tr:hover {
    background-color: #D9D9D9;
}

.vault-table-info>tbody>tr>td{
    padding: 10px 50px;
}




.button-submit.vault {
    text-transform: capitalize;
}

.buttons-vault-actions {
    display: flex;
    gap: 0px;
}

.button-vault-edit, .button-vault-delete {
    color: #000;
    background-color: #FFF;
    height: 30px;
    width: 35px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-vault-edit:hover, .button-vault-delete:hover {
    color: #000;
    cursor: pointer;
    background-color: #cdcccc;
    border-radius: 10%;
    
}

.input-vault-password {
    display: flex;
}
.icon-vault-visibility{
    display: flex;
    align-items: center;
    margin-left: -30px;
}

.vault-options {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 60px;
}

.name-item-vault {
    width: 100px;
    word-wrap: break-word;
    margin: 0px;
}

@media screen and (max-width: 920px){
    .inputs-form-vault {
        gap: 7px;
    }

    .vault-table-info>tbody>tr>td{
        padding: 10px 20px;
    }

    .vault-table-section{
        max-width: 110%;
    }
}