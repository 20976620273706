.NotificationsWindow {
    height: 500px;
    width: 400px;
    background: #D9D9D9;
    overflow: auto;
}

.NotificationsDisplay {
    padding: 20px;
}

.NotificationMessage {
    border-radius: 15px;
    background: #FFF;
    margin-bottom: 10px;
    padding: 10px 20px;
}

.NotificationMessage:hover{
    cursor: pointer;
}

.NotificationMessageText{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NotificationDate{
    font-family: 'Borna', sans-serif;
    font-size: 12px;
    color: #adadad;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: right;
}

.NotificationText {
    /* margin-left: 15px; */
    font-family: 'Borna', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
}

.NotificationIcon {
    max-height: 32px;
}

/* .popover-notifications{
    height: 500px;
    width: 39%;
    margin-left: 60%;
    margin-top: 65px;
    flex-shrink: 0;
    border-radius: 62px;
    background: #D9D9D9;
} */

@media screen and (max-width: 920px) {
    .NotificationsWindow {
        width: 100%;
    }
}