.button-submit.vault-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;
    border-radius: 10px;
    flex: none;
    flex-grow: 0;
    order: 0;
    flex-grow: 0;
    align-self: stretch;
    cursor: pointer;
    background: var(--main-petroleum-color);
    color: #FFFFFF;
}

.download-invoice-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.content-body
{
	border: 0;
	box-sizing: content-box;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	line-height: inherit;
	list-style: none;
	margin: 20;
	padding: 20;
	text-decoration: none;
	vertical-align: top;
}

/* heading */

.header-download-invoice {
  margin: 0 0 3em;
}

.h1-download-invoice {
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
}

.header-download-invoice .h1-download-invoice {
  background: black;
  border-radius: 0.25em;
  color: white;
  margin: 0 0 1em;
  padding: 0.5em 0;
}

.header-download-invoice .address-download-invoice {
  float: left;
  font-size: 75%;
  font-style: normal;
  line-height: 1.25;
  margin: 0 1em 1em 0;
}

.header-download-invoice .address-download-invoice .p-download-invoice {
  margin: 0 0 0.25em;
}

.header-download-invoice .span-download-invoice, .header-download-invoice .img-download-invoice { display: block; float: right; }
.header-download-invoice .span-download-invoice { margin: 0 0 1em 1em; max-height: 25%; max-width: 60%; position: relative; }
.header-download-invoice .img-download-invoice { max-height: 50%; max-width: 50%; }

.header-download-invoice:after { clear: both; content: ""; display: table; }

/* article */

.article-download-invoice, .article-download-invoice .address-download-invoice, .table-download-invoice { margin: 0 0 3em; }
.article-download-invoice .h1-download-invoice { clip: rect(0 0 0 0); position: absolute; }
.article-download-invoice .address-download-invoice { float: left; font-size: 125%; font-weight: bold; }

.article-download-invoice:after { clear: both; content: ""; display: table; }

/* table */

.table-download-invoice { font-size: 75%; table-layout: auto; width: 100%; }
.table-download-invoice { border-collapse: separate; border-spacing: 2px; }
.th-download-invoice, td-download-invoice { border-width: 1px; padding: 1em; position: relative; text-align: left; }
.th-download-invoice, td-download-invoice { border-radius: 0.25em; border-style: solid; }
.th-download-invoice { background: #EEE; border-color: #BBB; }
.td-download-invoice { border-color: #DDD; }

/* table firstTable */

.table-download-invoice.firstTable { float: right; width: 36%; height: 50%;}
.table-download-invoice.firstTable:after { clear: both; content: ""; display: table; }

/* table firstTable */

.table-download-invoice.firstTable th { width: 40%; }
.table-download-invoice.firstTable td { width: 60%; }

.form-download-invoice { font: 16px/1 'Open Sans', sans-serif; overflow: auto; padding: 0.5in; }
.form-download-invoice { background: #999; cursor: default; }

.form-download-invoice { box-sizing: border-box; height: 11in; margin: 0 auto; overflow: hidden; padding: 0.5in; width: 8.5in; }
.form-download-invoice { background: #FFF; border-radius: 1px; box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); }

.aside-download-invoice .h1-download-invoice#notes { border: none; border-width: 0 0 1px; margin: 0 0 1em; }
.aside-download-invoice .h1-download-invoice#notes { border-color: #999; border-bottom-style: solid; border-bottom-width: 10%; font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
}