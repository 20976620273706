.card-task-my-tasks-assistant {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: space-between;
}

.full-card-task {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 30px;
    gap: 25px;

    background: #FFFFFF;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    cursor: pointer;
}

.full-content-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 25px;

    order: 0;
    align-self: stretch;
    flex-grow: 0;
    width: 70%;
}

.header-card-task {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.card-task-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.card-task-title>h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #000000;
    margin: 0;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}

.card-task-owner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;


    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    height: 15px;
}

.card-task-owner>h4>.name-owner-task {
    font-weight: bold;
    color: var(--main-petroleum-color);
}

.card-task-owner>h4 {
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    /* Gris oscuro */

    color: #696969;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;

}

.counter-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.card-task-time {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;


    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    height: 15px;
}

.card-task-time>h4 {
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}


.card-task-title {
    font-family: 'borna';
    font-size: 20px;
}

.bar-timeout{
    width: 100%;
}

.icon-notes-user {
    color: var(--main-petroleum-color);
    font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width: 920px) {
    .card-task-my-tasks-assistant {
        flex-direction: column;
        gap: 0px;
    }

    .full-content-card {
        width: 100%;
    }
}