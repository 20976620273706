.cuadroInfo {
    display: flex;
    flex-direction: column;
    border-radius: 10px ;
    width: 446px;
    height: 316px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
}

.name-container{
    display: flex;
    width:100%;
    margin-bottom: 10px;
    align-items: center;
}

.client-name{
    width:80%;
    text-align: center;
    color: #000;
    font-family: 'Borna', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 10%;
}

.imgUser{
    border-radius: 70px;
    height:130px;
}

.button-addTask-preferred-icon, .button-addEvent-preferred-icon{
    height: 23px;
    color: #000;
}

.modal-task-by-assistant {
    z-index: 20000;
}

.icons-container {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 920px) {
    .cuadroInfo {
        flex-direction: column;
        gap: 0px;
        width: 100%;
    }
}