.date-limit-taskToApprove, .assistant-name-taskToApprove {
    width: 100%;
    text-align: right;
    /* color: black; */
    font-weight: 700;
    font-size: 12px;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: bolder;
}

.align-right {
    display: flex;
    justify-content: right;
    align-items: flex-start;
    margin-top: 8px;
}

.home-header{
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    margin: 0;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: var(--main-petroleum-color);
    font-family: 'nib';
    flex: none;
    order: 0;
    flex-grow: 1;
    margin-top: 20px;
}

.text-tasks-to-approve{
    font-family: 'aeonik', sans-serif;
}

.MuiTypography-root.MuiListItemText-primary{
    font-family: 'aeonik', sans-serif;
}
.MuiTypography-root.MuiListItemText-secondary{
    font-family: 'aeonik', sans-serif;
}