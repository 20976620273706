.assistant-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
    font-family: 'aeonik';
}
.css-1phucj-MuiTypography-root{
    font-family: 'aeonik' !important;
}
.assistant-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

a{
        text-decoration: none;
        color: var(--main-petroleum-color);
}

.pm-box-text{
    font-size: 26px;

    font-family: 'nib';
}

.pm-box-icon{
    font-size: 30px;
    color: var(--main-petroleum-color);
    font-family: 'aeonik';
}