.modal-task-header-right {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.modal-task-label {
    font-size: 15px;
    line-height: 15px;
    color: #000;
    font-weight: bold;
}

.modal-task-header-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-petroleum-color);
    padding-top: 10px;
    margin: 10px;
}

.modal-task-header-title>h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
    font-family: 'nib', sans-serif;
}

.modal-task-title, .modal-task-secrets {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    padding-left: 0px;
}

.modal-task-title-text, .modal-task-secrets-title {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
}

.modal-task-secrets-items {
    margin-top: 10px;
    padding: 15px;
    background-color: #FFF;
    word-wrap: break-word;
}

.modal-task-hours-date-limit {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.modal-task-recurrents {
    order: 3;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px;
}
.modal-task-recurrents-title {
    color: var(--main-petroleum-color);
}

.modal-task-table-recurrences {
    order: 4;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 10px;
}

.modal-task-table-recurrences>table>thead>tr>th {
    color: #ffffff;
    background: var(--main-petroleum-color);
    padding: 10px 0px;
    border: 1px solid #ebebec;
    font-weight: 500;
    border-radius: 5px;
}

.modal-task-table-recurrences>table>tbody>tr>td {
    border: 1px solid #e6edf1;
    padding: 15px;
}

.date-limit-popup-info-task {
    color: var(--main-petroleum-color);
}

.modal-task-progress-title {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
}

.modal-task-progress-bar {
    order: 5;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.modal-approve-button-container{
    order: 6;
    display: flex;
    width: 100%;
    justify-content: center;
}

.modal-task-progress-hours {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.info-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.modal-task-input-time {
    width: 4rem;
    border-radius: 8px;
    padding: 0px 10px;
    background-color: #fff;
    text-align: center;
}

.modal-task-input-time > .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 4px 0 5px;
    text-align: center;
}

.inputs-hours {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.maximum-hours {
    display: flex;
    align-items: center;
    padding-top: 20px;
}

.modal-task-assistant {
    width: 100%;
}

.modal-task-assistant-select {
    max-width: 300px;
}

.modal-task-assistant-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.popup-details-tasks-assistant {
    border: none;
    background-color: var(--main-graybk-color);
}

.header-popup-assistant{
    padding-bottom: 20px;
    border-bottom: 1px solid #000;
}

@media only screen and (max-width: 768px) {
    .modal-task-assistant-list {
        flex-direction: column;
        gap:10px;
        align-items: start;
        justify-content: start;
    }
}