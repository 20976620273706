.data-invoices-container{
    padding: 0 3%;
}

.table-invoices{
    margin: 10px 0;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.40);
    max-height: 400px;
    overflow-y: scroll;
}

.table-invoices::-webkit-scrollbar {
    width: 8px;
}

.table-invoices::-webkit-scrollbar-thumb {
    background-color: #9D9D9D;
    border-radius: 6px;
}

.table-invoices::-webkit-scrollbar-track {
    background-color: #f1f1f100;
}

.table-invoices-headers.MuiTableCell-root.MuiTableCell-head{
    text-align: center;
    color: #000;
    font-family: 'borna', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* .invoice-summary-body.MuiTableBody-root{
    position: fixed;
    max-height: 345px;
    overflow-y: scroll;
} */

.invoice-summary-row{
    background: #f5f5f5;
    margin: 2px 0;
}

.invoice-summary-row:hover {
    background-color: #d9d9d9;
    border-radius: 10px;
}
