.modal-rate-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-rate-title>h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
}

/* .modal-rate-title-text{
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
} */

.modal-rate-indication-text{
    font-weight: bold;
    margin-top:0px;
}

.modal-rate-rating-box, .modal-rate-button-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-rate-button-container{
    margin-top: 25px;
}

.modal-rate-button{
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 10px;
    width: 130px;
    height: 37px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.modal-rate-observations{
    margin:4% 0;
    width:100%;
}

.textarea-observations{
    width:100%;
    padding: 3% 1.5%;
}

@media screen and (max-width: 750px) {
    .modal-rate-observations{
        margin:15% 0;
    }

    .textarea-observations{
        padding: 8% 4%;
    }
}

.textarea-observations-title {
    position: absolute;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
    background-color: #fff;
    padding: 0px 10px;
    -webkit-transform: translate(0px, -9px) scale(0.75);
    -moz-transform: translate(0px, -9px) scale(0.75);
    -ms-transform: translate(0px, -9px) scale(0.75);
    transform: translate(0px, -9px) scale(0.75);
}

.modal-rate-checkbox{
    font-family: 'aeonik', sans-serif;
}
