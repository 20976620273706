.modal-repeat-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.modal-repeat-title>h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
}

.modal-repeat-button-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.modal-repeat-button{
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 10px;
    width: 140px;
    height: 37px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.type-field-task-repeat{
    display: grid; 
    grid-template-columns: 1fr 1fr;
    gap: 1rem; 
    padding-top: 20px;
}

.type-field-task-repeat.one-field{
    display: block;
}

.Return_Button-repeat{
    display: flex;
    align-items: flex-start;
    padding: 15px;
    gap: 10px;
    width: 30px;
    height: 33px;
    background: var(--main-petroleum-color);
    border-radius: 7px;
    margin-bottom:5px;
    order: 1;
    flex-grow: 0;
    border: none;
    cursor: pointer;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

@media screen and (max-width: 750px) {
    
}