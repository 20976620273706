.profile-container {
    display: flex;
    justify-content: center;
}

.content, .form-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-width: 565px;
    width: 565px;
}

.data-profile-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-bottom:10px;
    gap: 10px;
    background-color: #cecece00;

    border: 1px solid #000;

    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    flex: auto;
    order: 0;
    flex-grow: 1;
    outline: none;

    width: 50%;
}

.data-profile-input:hover, .data-profile-input:active, .data-profile-input:focus {
    border-color: var(--main-petroleum-color)
}

.data-profile-input.error {
    border: 1px solid var(--main-petroleum-color);
}

.data-profile-input>input.PhoneInputInput{
    background-color: #cecece00;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.panel{
    display: flex;
}

.panel-profile {
    justify-content: center;
    height: 150px;
    width: 150px;
}

.foto-user-profile {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    box-shadow: 0px 5px 16px rgba(3, 3, 3, 0.33);
}

label.name-user {
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 20px;
}

.profile-title {
    width: 75%;
}


.button-save-profile {
    width: 50%;
    margin-top: 10px;
}

.button-save-profile>.button-save, .button-save-img-profile, .button-charge-img-profile {
    width: 100%;
    min-height: 35px;
    font-size: 20px;
    color: #FFF;
    border-radius: 7px;
}

.button-vault-profile {
    margin-top: 10px;
}

.button-vault-profile>.button-vault {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 8px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    cursor: pointer;
    width: 100%;
    vertical-align: middle;
}

.popup-change-img-profile {
    border: none;
    background-color: var(--main-graybk-color);
}

.button-save-profile>.button-save, .imgUser-button-submit, .button-vault-profile>.button-vault, .button-save-img-profile, .button-charge-img-profile {
    background-color: var(--main-petroleum-color);
}

.button-save-img-profile.disabled {
    background-color: gray;
    color: rgb(51, 51, 51);
    border: none;
}

.button-save-profile>.button-save:hover, .button-vault-profile>.button-vault:hover {
    cursor: pointer;
}

.imgUser-button-submit {
    border-radius: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    cursor: pointer;
}

.contenedor-popup-change-img>.upload-photo, .fila1-avatares, .fila2-avatares {
    display: flex
}

.contenedor-popup-change-img>.upload-photo {
    align-items: center;
    gap:45px;
}

.fila1-avatares, .fila2-avatares {
    gap: 95px;
}

.imgUserProfile {
    position: relative;
}

.button-change-img {
    position: absolute;
    top: 0px;
    right: 5px;
}

.icon-button-change-img>svg {
    height: 30px;
    width: 30px;
}

.button-select-avatar {
    border-radius: 50%;
    border: none;
}

.button-select-avatar.selected {
    background-color: var(--main-green-color);
}

.avatar-button-icon {
    height: 96px;
    border-radius: 50%;
}


.save-select-img {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.choose-img-container {
    position: relative;
    display: inline-block;
}

.choose-img-container input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    right: 0;
    opacity: 0;
  }

.img-input-profile:hover, #file-upload-button:hover {
    cursor: pointer;
}


.css-sghohy-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #bc7868 !important;
}

.section-input-profile.info {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
    }

.input-profile>.error-message {
    position: absolute;
    bottom: -7px;
    left: 0;
    color: #FF5C34;
    font-size: 12px;
}

.title-input-profile {
    text-transform: capitalize;
}

.react-datepicker-wrapper{
    width: 100%;
}

@media screen and (max-width: 920px) {

    .fila1-avatares, .fila2-avatares {
        gap: unset;
    }


    .contenedor-popup-change-img>.upload-photo{
        gap: unset;
    }

    .content, .form-profile {
        flex-direction: column;
        padding: 0px;
        max-width: 565px;
        width: fit-content;
    }
    .right-panel-profile {
        padding: 0%;

        border-left: none;
        margin-right: 0px;

    }
}