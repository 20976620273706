.task-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.full-task-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 40px;
    position: relative;
}

.modal-task-upper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.modal-task-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
}

.modal-task-title {
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    letter-spacing: -0.01em;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.modal-task-owner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 10px;
    gap: 10px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.modal-task-description {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.modal-task-secrets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    order:2;
}

.modal-task {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 10px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    width: 100%;
}

.modal-task-description-text {
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    white-space: pre-line;

    color: #000000;
}

.modal-task-description-text > p, .modal-task-hours-date-limit>p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.full-asigned-task {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 86px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

}

.full-change-status-task {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    order: 1;
    flex-grow: 0;
}

.change-status-text {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 1;
    margin-left: -25px;
}

.change-status-form {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
    height: 40px;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.change-status-select {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    font-family: 'aeonik';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    text-align: center;
}

.change-status-button {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;

    background: var(--main-petroleum-color);
    border-radius: 8px;

    flex: none;
    order: 1;
    flex-grow: 0;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;

    text-align: center;
    color: #FFFFFF;

    cursor: pointer;
    width: 70px;
    height: 40px;
}

.change-status-button:hover {
    background: var(--main-petroleum-color);
}

@media screen and (max-width: 920px) {
    .full-asigned-task {
        flex-direction: column;
        gap: 10px;
    }  
    .full-task-modal {
        padding: 20px 0px;
    }  
    .inputs-hours {
        gap: 0px;
    }
    
}
