input[type=checkbox]:checked + label.todo_label{
    text-decoration: line-through;
}
.notes-general-container{
    padding: 0 4%;
}

.note-title{
    font-style: normal;
    font-size: 2em;
    letter-spacing: -0.01em;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    color: var(--main-petroleum-color);
    margin:0 15px;
}

.add-note-button-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px;
    left: 0;
    bottom: 0;
    color: white;
    text-align: center;
}

.text-list{
    text-align: left;
}

.notes-add-button-client{
    display: flex;
    width: 20%;
    height: 45px;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border-color: transparent;
    font-weight: bold;
    color: #FFF;
    background: var(--main-petroleum-color);
    cursor: pointer;
}

.inputSearch{
    height:45px;
    width:73%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.25);
}

.list-notes{
    height: 375px;
    overflow-y: scroll;
    padding-right: 10px;
}

.list-notes::-webkit-scrollbar {
    width: 8px;
}

.list-notes::-webkit-scrollbar-thumb {
    background-color: #9D9D9D;
    border-radius: 6px;
}

.list-notes::-webkit-scrollbar-track {
    background-color: #f1f1f100;
}

.note-item{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    padding: 10px;
}

.pinned-item{
    background-color: var(--main-green-color);
}

.pin-icon{
    color: var(--main-petroleum-color)
}

.other-icon{
    color:#000;
}