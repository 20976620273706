.unassigned-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 35px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.full-unassigned-task {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 86px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
}

.tasks-info-unassigned {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;
    position: relative;
    top: 50px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.unassigned-card-task {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 20px;
    gap: 25px;


    background: #FFFFFF;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    cursor: pointer;
}

.take-task-button {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;

    background: var(--main-petroleum-color);
    border: none;
    border-radius: 8px;

    width: 119px;
    height: 40px;

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;

    font-family: 'aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #FFFFFF;
}

.take-task-button:hover {
    background: var(--main-green-color);
}

.full-take-task {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    order: 1;
    flex-grow: 0;
}

.popup-info-task {
    border: none;
    background-color: var(--main-graybk-color);
}

@media screen and (max-width: 920px) {
    .full-unassigned-task {
        flex-direction: column;
        gap: 10px;
    }    
}
